





































































































































import { defineComponent, onMounted } from '@vue/composition-api/dist/vue-composition-api';
import CompanyMenuComponent from '@/components/Management/molecules/CompanyMenu.vue';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import RestoreTenantSearchComponent from '@/components/Management/organisms/RestoreTenantSearch.vue';
import { useDate } from '@/composables/function/Common/useDate';
import RestoreConfirmDialogComponent from '@/components/Management/organisms/RestoreConfirmDialog.vue';
import { useRestoreDialog } from '@/composables/store/Management/Restore/useRestoreDialog';
import { useRestoreTenant } from '@/composables/store/Management/Restore/useRestoreTenant';
import { useFormValidation } from '@/composables/function/Management/useFormValidation';

export default defineComponent({
    components: {
        CompanyMenu: CompanyMenuComponent,
        ProgressCircular: ProgressCircularComponent,
        RestoreTenantSearch: RestoreTenantSearchComponent,
        RestoreConfirmDialog: RestoreConfirmDialogComponent,
    },
    setup() {
        const { isRestoreSuccess, openDialog } = useRestoreDialog();
        const {
            error,
            startDate,
            endDate,
            isValid,
            showTenantSearch,
            selectTenantIdentifiers,
            initTenantSelect,
            tenantAllSelect,
            domainList,
            companyList,
            fetchCompanyList,
            fetchDomainList,
            isCompanyFetchProgressing,
            isRestoreProgressing,
        } = useRestoreTenant();

        onMounted(async () => {
            await fetchDomainList();
            // NOTE: 会社情報とドメイン名を紐づけるためドメインを取得してから会社情報をフェッチ
            await fetchCompanyList();
        });

        const { validateDateRange } = useFormValidation();

        const { getDateNumber, getToday } = useDate();

        return {
            error,
            initTenantSelect,
            tenantAllSelect,
            selectTenantIdentifiers,
            isRestoreSuccess,
            openDialog,
            isValid,
            validateDateRange,
            getToday,
            getDateNumber,
            startDate,
            endDate,
            showTenantSearch,
            domainList,
            companyList,
            isCompanyFetchProgressing,
            isRestoreProgressing,
        };
    },
});
