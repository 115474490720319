import { inject, provide, Ref, ref } from '@vue/composition-api';
import { FetchCompanyTenantListService } from '@/models/service/Company/FetchCompanyTenantListService';
import { RestoreTenantsService } from '@/models/service/Management/RestoreTenantsService';
import { container } from 'tsyringe';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { CompanyTenantInfo, TenantInfo } from '@/models/interface/Management/ICompanyRepository';
import { StatsServerFetchListService } from '@/models/service/Management/StatsServerFetchListService';
import { StatsServerInfo } from '@/models/interface/Management/IStatsServerRepository';
import { useDate } from '@/composables/function/Common/useDate';
import { useFormValidation } from '@/composables/function/Management/useFormValidation';

const CompanyTenantFetchIndex = container.resolve(FetchCompanyTenantListService);
const RestoreTenants = container.resolve(RestoreTenantsService);
const StatsServerFetchIndex = container.resolve(StatsServerFetchListService);

type TenantDataType = {
    identifier: string;
    name: string;
    companyName: string;
    statsServerId: number;
    domain: string;
};

type SearchFormType = {
    companyName: string;
    statsServerId: number;
    tenantName: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideRestoreTenant = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });
    const searchForm = ref<SearchFormType>({
        companyName: '',
        statsServerId: 0,
        tenantName: '',
    });
    const { getYesterday, getToday } = useDate();
    const tenantList: Ref<TenantDataType[]> = ref([]);
    const selectTenantIdentifiers: Ref<string[]> = ref([]);
    const isRestoreSuccess = ref(false);
    const showTenantSearch = ref(false);
    const displayTenantList = ref([]);
    const domainList: Ref<StatsServerInfo[]> = ref([]);
    const companyList: Ref<string[]> = ref([]);
    const isAllTenantSelected = ref(false);
    const startDate: Ref<string> = ref(getYesterday());
    const endDate: Ref<string> = ref(getToday());
    const isCompanyFetchProgressing: Ref<boolean> = ref(true);
    const isRestoreProgressing: Ref<boolean> = ref(false);

    provide('error', error);
    provide('tenantList', tenantList);
    provide('searchForm', searchForm);
    provide('selectTenantIdentifiers', selectTenantIdentifiers);
    provide('isRestoreSuccess', isRestoreSuccess);
    provide('showTenantSearch', showTenantSearch);
    provide('domainList', domainList);
    provide('companyList', companyList);
    provide('displayTenantList', displayTenantList);
    provide('isAllTenantSelected', isAllTenantSelected);
    provide('startDate', startDate);
    provide('endDate', endDate);
    provide('isCompanyFetchProgressing', isCompanyFetchProgressing);
    provide('isRestoreProgressing', isRestoreProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useRestoreTenant = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const searchForm = inject('searchForm') as Ref<SearchFormType>;
    const selectTenantIdentifiers = inject('selectTenantIdentifiers') as Ref<string[]>;
    const tenantList = inject('tenantList') as Ref<TenantDataType[]>;
    const displayTenantList = inject('displayTenantList') as Ref<TenantDataType[]>;
    const isRestoreSuccess = inject('isRestoreSuccess') as Ref<boolean>;
    const showTenantSearch = inject('showTenantSearch') as Ref<boolean>;
    const domainList = inject('domainList') as Ref<StatsServerInfo[]>;
    const companyList = inject('companyList') as Ref<string[]>;
    const isAllTenantSelected = inject('isAllTenantSelected') as Ref<boolean>;
    const startDate = inject('startDate') as Ref<string>;
    const endDate = inject('endDate') as Ref<string>;
    const isCompanyFetchProgressing = inject('isCompanyFetchProgressing') as Ref<boolean>;
    const isRestoreProgressing = inject('isRestoreProgressing') as Ref<boolean>;

    const { validateDateRange } = useFormValidation();

    const isValid = (): boolean => {
        return validateDateRange(startDate.value, endDate.value) && selectTenantIdentifiers.value.length > 0;
    };

    const tenantAllSelect = (): void => {
        selectTenantIdentifiers.value = tenantList.value.map(tenant => tenant.identifier);
    };

    const initTenantSelect = (): void => {
        // 選択テナントと絞りこみ条件を初期化
        selectTenantIdentifiers.value = [];
        searchForm.value = {
            companyName: '',
            statsServerId: 0,
            tenantName: '',
        };
        isAllTenantSelected.value = false;
    };

    const fetchCompanyList = async (): Promise<void> => {
        isCompanyFetchProgressing.value = true;
        const response = await CompanyTenantFetchIndex.handle();
        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            return;
        }
        setCompanyList(response.data.data);
        // fetch後 検索条件に対応したテナントの検索をかける
        filterTenant();
        // 全テナントを選択
        tenantAllSelect();
        isCompanyFetchProgressing.value = false;
    };

    const setCompanyList = (data: CompanyTenantInfo[]): void => {
        data.map((info: CompanyTenantInfo) => {
            const companyName = info.name;
            companyList.value.push(companyName);
            info.tenants.map((tenant: TenantInfo) => {
                const domain = domainList.value.find(domain => domain.id == tenant.statsServerId)?.domain ?? '';
                tenantList.value.push({
                    companyName: companyName,
                    identifier: tenant.identifier,
                    name: tenant.name,
                    statsServerId: tenant.statsServerId,
                    domain: domain,
                });
            });
        });
    };

    const fetchDomainList = async (): Promise<void> => {
        const response = await StatsServerFetchIndex.handle();
        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            return;
        }
        setDomainList(response.data.data);
    };

    const setDomainList = (statsServerInfo: StatsServerInfo[]): void => {
        domainList.value = statsServerInfo;
    };

    const isAllSelected = (): boolean => {
        if (displayTenantList.value.length === 0) return false;
        return displayTenantList.value.every(tenant => {
            return selectTenantIdentifiers.value.includes(tenant.identifier);
        });
    };

    const filterTenant = (): void => {
        displayTenantList.value = tenantList.value.filter(tenant => {
            // NOTE: 会社、ドメイン、テナント名のand検索絞り込み
            return (
                (searchForm.value.companyName === '' ||
                    tenant.companyName.normalize().indexOf(searchForm.value.companyName) > -1) &&
                (searchForm.value.statsServerId === 0 || tenant.statsServerId === searchForm.value.statsServerId) &&
                (searchForm.value.tenantName === '' ||
                    tenant.name.normalize().indexOf(searchForm.value.tenantName) > -1 ||
                    tenant.identifier.normalize().indexOf(searchForm.value.tenantName) > -1)
            );
        });
    };

    const setIsAllTenantSelected = (): void => {
        isAllTenantSelected.value = isAllSelected();
    };

    const restoreExecute = (): void => {
        isRestoreProgressing.value = true;
        // TODO: リクエスト成功可否は一旦無視、復旧状況について別途表示する
        RestoreTenants.handle(startDate.value, endDate.value, selectTenantIdentifiers.value);
        isRestoreProgressing.value = false;
        isRestoreSuccess.value = true;
    };

    return {
        error,
        isCompanyFetchProgressing,
        restoreExecute,
        fetchDomainList,
        fetchCompanyList,
        tenantList,
        filterTenant,
        displayTenantList,
        searchForm,
        selectTenantIdentifiers,
        isRestoreSuccess,
        showTenantSearch,
        tenantAllSelect,
        initTenantSelect,
        domainList,
        companyList,
        isValid,
        isAllTenantSelected,
        startDate,
        endDate,
        isRestoreProgressing,
        isAllSelected,
        setIsAllTenantSelected,
    };
};
