import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideRestoreDialog = () => {
    const isShow = ref(false);
    const isRestoreSuccess = ref(false);

    provide('isShow', isShow);
    provide('isRestoreSuccess', isRestoreSuccess);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useRestoreDialog = () => {
    const isShow = inject('isShow') as Ref<boolean>;
    const isRestoreSuccess = inject('isRestoreSuccess') as Ref<boolean>;

    const openDialog = (): void => {
        isShow.value = true;
    };

    const closeDialog = (): void => {
        isShow.value = false;
    };

    return {
        isRestoreSuccess,
        isShow,
        closeDialog,
        openDialog,
    };
};
