import { TenantRestoreResponse, ITenantMRepository } from '@/models/interface/Management/ITenantMRepository';
import { RestoreRequestForm } from '@/types/restore';
import { inject, injectable } from 'tsyringe';

@injectable()
export class RestoreTenantsService {
    constructor(@inject('ITenantMRepository') private readonly repository: ITenantMRepository) {}

    async handle(startDate: string, endDate: string, tenantIdentifiers: string[]): Promise<TenantRestoreResponse> {
        const params: RestoreRequestForm = {
            tenantIdentifiers: tenantIdentifiers,
        };
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;
        return this.repository.restoreTenants(params);
    }
}
