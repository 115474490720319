







import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import RestoreForm from '@/components/Management/ecosystems/RestoreForm.vue';
import { provideRestoreDialog } from '@/composables/store/Management/Restore/useRestoreDialog';
import { provideRestoreTenant } from '@/composables/store/Management/Restore/useRestoreTenant';
import RestoreConfirmDialog from '@/components/Management/organisms/RestoreConfirmDialog.vue';

export default defineComponent({
    components: {
        RestoreForm: RestoreForm,
        RestoreConfirmDialog: RestoreConfirmDialog,
    },
    setup() {
        provideRestoreDialog();
        provideRestoreTenant();
    },
});
