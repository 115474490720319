






















import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useRestoreDialog } from '@/composables/store/Management/Restore/useRestoreDialog';
import { useRestoreTenant } from '@/composables/store/Management/Restore/useRestoreTenant';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },

    setup() {
        const { isShow, closeDialog } = useRestoreDialog();
        const { restoreExecute } = useRestoreTenant();

        const reAnalysis = (): void => {
            closeDialog();
            restoreExecute();
        };

        return {
            isShow,
            closeDialog,
            reAnalysis,
            restoreExecute,
        };
    },
});
