
















































































import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import { useRestoreTenant } from '@/composables/store/Management/Restore/useRestoreTenant';

export default defineComponent({
    setup() {
        const {
            displayTenantList,
            tenantList,
            filterTenant,
            setIsAllTenantSelected,
            searchForm,
            selectTenantIdentifiers,
            domainList,
            companyList,
            isAllTenantSelected,
            isAllSelected,
        } = useRestoreTenant();

        const tenantChecked = (identifier: string): void => {
            if (selectTenantIdentifiers.value.includes(identifier)) {
                selectTenantIdentifiers.value = selectTenantIdentifiers.value.filter(tenant => tenant !== identifier);
            } else {
                selectTenantIdentifiers.value = [...selectTenantIdentifiers.value, identifier];
            }
            // 全選択のチェックボックスの状態を更新
            isAllTenantSelected.value = isAllSelected();
            return;
        };

        const allSelect = (): void => {
            if (displayTenantList.value.length === 0) return;
            // フィルタリング条件と一致するものを全選択
            selectTenantIdentifiers.value = Array.from(
                new Set([
                    ...(selectTenantIdentifiers.value ?? []),
                    ...displayTenantList.value.map(tenant => tenant.identifier),
                ]),
            );
        };

        const allUnSelect = (): void => {
            if (displayTenantList.value.length === 0) return;
            // フィルタリング条件と一致するものを全解除
            const displayTenantIdentifierList = displayTenantList.value.map(tenant => tenant.identifier);
            selectTenantIdentifiers.value = selectTenantIdentifiers.value.filter(
                tenantIdentifier => !displayTenantIdentifierList.includes(tenantIdentifier),
            );
        };

        const clearSearchFormFields = (): void => {
            Object.keys(searchForm.value).forEach(key => {
                const formKey = key as keyof typeof searchForm.value;

                if (searchForm.value[formKey] === null) {
                    // NOTE: 収容サーバー検索時は初期値を0にする
                    (searchForm.value[formKey] as number | string) = formKey === 'statsServerId' ? 0 : '';
                }
            });
        };

        const searchTenant = (): void => {
            // NOTE: vuetify clearableで値をクリアするとnullになるので、検索処理前に空文字変換
            clearSearchFormFields();
            filterTenant();
            setIsAllTenantSelected();
        };

        return {
            searchTenant,
            searchForm,
            tenantChecked,
            tenantList,
            displayTenantList,
            allSelect,
            allUnSelect,
            selectTenantIdentifiers,
            isAllTenantSelected,
            domainList,
            companyList,
            clearSearchFormFields,
        };
    },
});
